import React from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import Links from "../../components/common/Links";

const Workplace = () => {
  return (
    <div>
      <Breadcrumb title="Workplace" parent="Workplace" />
      <Links pathname={window.location.pathname} />
    </div>
  );
};
export default Workplace;
