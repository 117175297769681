import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { BrowserRouter as Router, Switch, Route, withRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import RoleRoute from "./routes/RoleRoute";
import { AuthUserProvider } from "./authUserContext";

// ** Import custom components for redux **
import { Provider } from "react-redux";
import store from "./store";
import App from "./components/app";

// Dashboard
import Project from "./pages/dashboard/project/project";
import MigrationGroupsList from "./pages/dashboard/migration-groups/MigrationGroupsList";
import MigrationGroupEdit from "./pages/dashboard/migration-groups/MigrationGroupEdit";
import BusinessApplicationsList from "./pages/discovery/business-applications/BusinessApplicationsList";
import BusinessApplicationEdit from "./pages/discovery/business-applications/BusinessApplicationEdit";

// Discovery
import ApplicationComponentsList from "./pages/discovery/application-components/ApplicationComponentsList";
import ApplicationComponentEdit from "./pages/discovery/application-components/ApplicationComponentEdit";
import DiscoveryTools from "./pages/discovery/tools/DiscoveryTools";
import VirtualServersList from "./pages/discovery/virtual-servers/VirtualServerList";
import VirtualServerEdit from "./pages/discovery/virtual-servers/VirtualServerEdit";
import PhysicalServersList from "./pages/discovery/physical-servers/PhysicalServersList";
import PhysicalServerEdit from "./pages/discovery/physical-servers/PhysicalServerEdit";
import DatabaseList from "./pages/discovery/databases/DatabaseList";
import DatabaseEdit from "./pages/discovery/databases/DatabaseEdit";
import KubernetesList from "./pages/discovery/kubernetes/KubernetesList";
import KubernetesEdit from "./pages/discovery/kubernetes/KubernetesEdit";
import Topology from "./pages/discovery/topology/Topology";

// Assessments
import ServerAssessment from "./pages/assessments/ServerAssessment";
import ApplicationAssessment from "./pages/assessments/ApplicationAssessment";
import DatabaseAssessment from "./pages/assessments/DatabaseAssessment";

// Enterprise Architecture
import EnterpriseArchitecture from "./pages/enterprise-architecture/EnterpriseArchitecture";

// Database Factory
import OracleToOracle from "./pages/database-factory/ora2ora/OracleToOracle";
import CreateOra2OraMigration from "./pages/database-factory/ora2ora/CreateOra2OraMigration";
import PostgresToPostgres from "./pages/database-factory/postgres2postgres/PostgresToPostgres";
import CreatePostgres2PostgresMigration from "./pages/database-factory/postgres2postgres/CreatePostgres2PostgresMigration";
import Mongo from "./pages/database-factory/Mongo";
import WorkFlowTool from "./pages/database-factory/WorkFlowTool";
import MigrationProjects from "./pages/database-factory/fdm/MigrationProjects";
import ProjectMigrations from "./pages/database-factory/fdm/MigrationList";

// Application Migration
import SourceServers from "./pages/application-migration/SourceServers";

// Service
import ServiceNow from "./pages/service/ServiceNow";

// Sustainability
import Recommendations from "./pages/sustainability/Recommendations";
import ServerSpecs from "./pages/sustainability/ServerSpecs";
import SustainabilityAssessment from "./pages/sustainability/SustainabilityAssessment";
import ServerPowerRatings from "./pages/sustainability/ServerPowerRatings";
import CarbonFootprint from "./pages/sustainability/CarbonFootprint";
import FactorsRepository from "./pages/sustainability/FactorsRepository";
import SustainabilityEdit from "./pages/sustainability/AssessmentEdit";
import AssementResult from "./pages/sustainability/AssementResult";

// CICD
import Repository from "./pages/repository/Repository";
import GitLabTriggers from "./pages/catalogue/GitLabTriggers";

// Workplace
import Workplace from "./pages/workplace/Workplace";

// Kozuchi
import Kozuchi from "./pages/kozuchi/Kozuchi";

// Vault
import Vault from "./pages/vault/Vault";
import AddSecret from "./pages/vault/add-secret/AddSecret";

// Infrastructure
import GCPConsole from "./pages/infrastructure/GCPConsole";
import GCPBuild from "./pages/infrastructure/iac-build/GCPBuild";
import AzureDevopsGCPBuild from "./pages/infrastructure/azuredevops-gcp-vm/GCPBuild";
import OCIDatabaseBuild from "./pages/infrastructure/oci-database/OCIBuild";
import OrderAzureDevopsGCPBuild from "./pages/infrastructure/azuredevops-gcp-vm/OrderGCPBuild";
import OrderOCIDatabaseBuild from "./pages/infrastructure/oci-database/OrderOCIBuild";
import OrderGCPBuild from "./pages/infrastructure/iac-build/OrderGCPBuild";
import GCPSQL from "./pages/infrastructure/cloudsql-build/GCPSQL";
import OrderGCPSQL from "./pages/infrastructure/cloudsql-build/OrderGCPSQL";
import AlibabaCloud from "./pages/infrastructure/AlibabaCloud";
import AWSCloud from "./pages/infrastructure/AWSCloud";
import EC2Build from "./pages/infrastructure/ec2-build/EC2Build";
import OrderEC2Build from "./pages/infrastructure/ec2-build/OrderEC2Build";
import AZRVMBuild from "./pages/infrastructure/azrvm-build/AZRVMBuild";
import OrderAZRVMBuild from "./pages/infrastructure/azrvm-build/OrderAZRVMBuild";
import OrderPackagedDeploymnetBuild from "./pages/infrastructure/packaged-deployment/OrderPackagedDeploymnetBuild";
import PackagedDeploymentBuild from "./pages/infrastructure/packaged-deployment/PackagedDeploymentBuild";
import AzureDevopsAzureVMBuild from "./pages/infrastructure/azuredevops-azr-vm/AzureVMBuild";
import OrderAzureDevopsAzureVMBuild from "./pages/infrastructure/azuredevops-azr-vm/OrderAzureVMBuild";
import AzureDevopsAzurePostgresBuild from "./pages/infrastructure/azuredevops-azr-postgres/AzurePostgresBuild";
import OrderAzureDevopsAzurePostgresBuild from "./pages/infrastructure/azuredevops-azr-postgres/OrderAzurePostgresBuild";
import VendAccount from "./pages/infrastructure/aws-account/VendAccount";
import OrderVendAccount from "./pages/infrastructure/aws-account/OrderVendAccount";

// API
import Kong from "./pages/api/Kong";
import Application from "./pages/api/OracleWebApp";

// Data
import DataBus from "./pages/data/DataBus";
import DataVirtualisation from "./pages/data/DataVirtualisation";

// Users
import UserEdit from "./components/users/userEdit";

// Misc.
import Maintenance from "./pages/maintenance";
import KnowledgebaseComponent from "./pages/knowledgebase/Knowledgebase";
import SupportTicket from "./pages/support-ticket/SupportTicket";

//Error Pages
import Error400 from "./pages/errors/error400";
import Error401 from "./pages/errors/error401";
import Error403 from "./pages/errors/error403";
import Error404 from "./pages/errors/error404";
import Error500 from "./pages/errors/error500";
import Error503 from "./pages/errors/error503";

// Import Applications Components
import Products from "./pages/catalogue/Products";
import WishlistComponent from "./pages/applications/ecommerce-app/wishlist";
import ProductDetail from "./pages/applications/ecommerce-app/product-detail/product-detail";
import Invoice from "./pages/applications/ecommerce-app/invoice";
import Checkout from "./pages/applications/ecommerce-app/checkout";
import ProductList from "./pages/catalogue/ProductList";
import Payment from "./pages/applications/ecommerce-app/payment";
import History from "./pages/applications/ecommerce-app/history";

// Admin
import Roles from "./pages/admin/Roles";
import RolesEdit from "./pages/admin/RolesEdit";
import Links from "./pages/admin/Links";
import CreateAssessment from "./pages/admin/CreateAssessment";

import ComponentLayout from "./components/componentLayout";
import { ToastContainer } from "react-toastify";
import Globals from "./pages/admin/Globals";

const Root = (props) => {
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    props.history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + "/login/callback",
    scopes: ["openid", "email", "profile", "groups"]
  });

  const onAuthRequired = () => {
    props.history.push("/login");
  };

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  return (
    <div className="App">
      <ToastContainer />
      <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired} restoreOriginalUri={restoreOriginalUri}>
        <AuthUserProvider>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/login`} component={login} />
            <Route exact path={`${process.env.PUBLIC_URL}/login/callback`} component={LoginCallback} />
            <Route path={`${process.env.PUBLIC_URL}/maintenance`} component={Maintenance} />
            <Route path={`${process.env.PUBLIC_URL}/400`} component={Error400} />
            <Route path={`${process.env.PUBLIC_URL}/401`} component={Error401} />
            <Route path={`${process.env.PUBLIC_URL}/403`} component={Error403} />
            <Route path={`${process.env.PUBLIC_URL}/404`} component={Error404} />
            <Route path={`${process.env.PUBLIC_URL}/500`} component={Error500} />
            <Route path={`${process.env.PUBLIC_URL}/503`} component={Error503} />
            <App>
              {/* dashboard menu */}
              <ComponentLayout section={"Dashboard"}>
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/`} component={Project} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/dashboard/project`} component={Project} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/dashboard/migration-groups`} component={MigrationGroupsList} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/dashboard/migration-groups/edit/:id`} component={MigrationGroupEdit} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/dashboard/migration-groups/add`} component={MigrationGroupEdit} />
              </ComponentLayout>

              {/* Discovery Menu */}
              <ComponentLayout section={"Discovery"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/discovery/tools`} component={withRouter(DiscoveryTools)} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/business-applications`} component={BusinessApplicationsList} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/business-applications/edit/:id`} component={BusinessApplicationEdit} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/business-applications/add`} component={BusinessApplicationEdit} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/application-components`} component={withRouter(ApplicationComponentsList)} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/application-components/edit/:id`} component={withRouter(ApplicationComponentEdit)} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/application-components/add`} component={withRouter(ApplicationComponentEdit)} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/virtual-servers`} component={VirtualServersList} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/virtual-servers/edit/:discovery/:id`} component={VirtualServerEdit} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/virtual-servers/add/:discovery`} component={VirtualServerEdit} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/physical-servers`} component={PhysicalServersList} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/physical-servers/edit/:discovery/:id`} component={PhysicalServerEdit} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/physical-servers/add/:discovery/`} component={PhysicalServerEdit} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/databases`} component={DatabaseList} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/databases/edit/:discovery/:id`} component={DatabaseEdit} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/databases/add/:discovery`} component={DatabaseEdit} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/kubernetes`} component={KubernetesList} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/kubernetes/edit/:discovery/:id`} component={KubernetesEdit} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/kubernetes/add/:discovery`} component={KubernetesEdit} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/discovery/topology`} component={Topology} />
              </ComponentLayout>

              {/* Assessments Menu */}
              <ComponentLayout section={"Assessments"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/server-assessment`} component={ServerAssessment} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/application-assessment`} component={ApplicationAssessment} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/database-assessment`} component={DatabaseAssessment} />
              </ComponentLayout>

              {/* Enterprise Architecture Menu */}
              <ComponentLayout section={"Enterprise Architecture"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/enterprise-architecture`} component={EnterpriseArchitecture} />
              </ComponentLayout>

              {/* Database Factory Menu */}
              <ComponentLayout section={"Database Factory"}>
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/database-factory/oracle-to-postgresql`} component={MigrationProjects} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/database-factory/oracle-to-postgresql/migrations/:id`} component={ProjectMigrations} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/database-factory/postgres-to-postgres`} component={PostgresToPostgres} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/database-factory/postgres-to-postgres/create`} component={CreatePostgres2PostgresMigration} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/database-factory/oracle-to-oracle`} component={OracleToOracle} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/database-factory/oracle-to-oracle/create`} component={CreateOra2OraMigration} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/database-factory/oracle-to-oracle/create/:id`} component={CreateOra2OraMigration} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/database-factory/workflow-tool`} component={WorkFlowTool} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/database-factory/mongodb`} component={Mongo} />
              </ComponentLayout>

              {/* Application Migration Menu */}
              <ComponentLayout section={"Application Migration"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/application-migration/source-servers`} component={SourceServers} />
              </ComponentLayout>

              {/* Service */}
              <ComponentLayout section={"Service"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/service/service-now`} component={ServiceNow} />
              </ComponentLayout>

              {/* APIs Menu */}
              <ComponentLayout section={"APIs"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/apis/kong`} component={Kong} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/apis/oracle-web-app`} component={Application} />
              </ComponentLayout>

              {/* Repository Menu */}
              <ComponentLayout section={"Repository"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/repository`} component={Repository} />
              </ComponentLayout>

              {/* Workplace Menu */}
              <ComponentLayout section={"Workplace"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/workplace`} component={Workplace} />
              </ComponentLayout>

              {/* Kozuchi Menu */}
              <ComponentLayout section={"Kozuchi"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/kozuchi`} component={Kozuchi} />
              </ComponentLayout>

              {/* Catalogue Menu */}
              <ComponentLayout section={"Catalogue"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/catalogue/db-migrations`} component={Products} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/catalogue/gitlab-trigger`} component={GitLabTriggers} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/catalogue/product-list`} component={ProductList} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/catalogue/wishlist/:id`} component={WishlistComponent} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/catalogue/product-detail/:id`} component={ProductDetail} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/catalogue/checkout`} component={Checkout} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/catalogue/invoice`} component={Invoice} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/catalogue/payment`} component={Payment} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/catalogue/history`} component={History} />
              </ComponentLayout>

              {/* Infrastructure Menu */}
              <ComponentLayout section={"Infrastructure"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/infrastructure/aws/aws-console`} component={AWSCloud} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/infrastructure/google/gcp-console`} component={GCPConsole} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/google/iac-build/order`} component={OrderGCPBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/google/iac-build/order/:id`} component={OrderGCPBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/google/iac-build`} component={GCPBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/google/packaged-deployment/order`} component={OrderPackagedDeploymnetBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/google/packaged-deployment/order/:id`} component={OrderPackagedDeploymnetBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/google/packaged-deployment`} component={PackagedDeploymentBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/google/cloudsql-build/order`} component={OrderGCPSQL} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/google/cloudsql-build/order/:id`} component={OrderGCPSQL} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/google/cloudsql-build`} component={GCPSQL} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/aws/ec2-build/order`} component={OrderEC2Build} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/aws/ec2-build/order/:id`} component={OrderEC2Build} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/aws/ec2-build`} component={EC2Build} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/aws/vend-account/order`} component={OrderVendAccount} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/aws/vend-account/order/:id`} component={OrderVendAccount} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/aws/vend-account`} component={VendAccount} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/azr/azrvm-build/order`} component={OrderAZRVMBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/azr/azrvm-build/order/:id`} component={OrderAZRVMBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/azr/azrvm-build`} component={AZRVMBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/google/azuredevops-gcp-vm/order`} component={OrderAzureDevopsGCPBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/google/azuredevops-gcp-vm/order/:id`} component={OrderAzureDevopsGCPBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/google/azuredevops-gcp-vm`} component={AzureDevopsGCPBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/oracle/oci-database`} component={OCIDatabaseBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/oracle/oci-database/order`} component={OrderOCIDatabaseBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/oracle/oci-database/order/:id`} component={OrderOCIDatabaseBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/azr/azuredevops-azr-vm`} component={AzureDevopsAzureVMBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/azr/azuredevops-azr-vm/order`} component={OrderAzureDevopsAzureVMBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/azr/azuredevops-azr-vm/order/:id`} component={OrderAzureDevopsAzureVMBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/azr/azuredevops-azr-postgres`} component={AzureDevopsAzurePostgresBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/azr/azuredevops-azr-postgres/order`} component={OrderAzureDevopsAzurePostgresBuild} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/infrastructure/azr/azuredevops-azr-postgres/order/:id`} component={OrderAzureDevopsAzurePostgresBuild} />

                <RoleRoute path={`${process.env.PUBLIC_URL}/infrastructure/alibaba`} component={AlibabaCloud} />
              </ComponentLayout>

              {/* Secrets Menu */}
              <ComponentLayout section={"Secrets"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/secrets/vault`} component={Vault} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/secrets/add-secret`} component={AddSecret} />
              </ComponentLayout>

              {/* Sustainability Menu */}
              <ComponentLayout section={"Sustainability"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/sustainability/recommendations`} component={Recommendations} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/sustainability/server-power-ratings`} component={ServerPowerRatings} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/sustainability/server-specs`} component={ServerSpecs} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/sustainability/sustainability-assessment`} component={SustainabilityAssessment} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/sustainability/sustainability-result`} component={AssementResult} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/sustainability/carbon-footprint`} component={CarbonFootprint} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/sustainability/factors-repository`} component={FactorsRepository} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/sustainability/sustainability-assessment/edit/:id`} component={SustainabilityEdit} />
              </ComponentLayout>

              {/* Data Virtualisation Menu */}
              <ComponentLayout section={"Data"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/data/data-virtualisation`} component={DataVirtualisation} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/data/data-bus`} component={DataBus} />
              </ComponentLayout>

              {/* Knowledgebase Menu */}
              <ComponentLayout section={"Knowledgebase"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/knowledgebase`} component={KnowledgebaseComponent} />
              </ComponentLayout>

              {/* Users Menu */}
              <SecureRoute path={`${process.env.PUBLIC_URL}/users/userEdit`} component={UserEdit} />

              {/* Support Ticket Menu */}
              <ComponentLayout section={"Support Ticket"}>
                <RoleRoute path={`${process.env.PUBLIC_URL}/support-ticket`} component={SupportTicket} />
              </ComponentLayout>

              {/* Admin Menu */}
              <ComponentLayout section={"Admin"}>
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/admin/roles`} component={Roles} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/admin/roles/edit`} component={RolesEdit} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/admin/roles/edit/:id`} component={RolesEdit} />
                <RoleRoute exact path={`${process.env.PUBLIC_URL}/admin/links`} component={Links} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/admin/assessments/create`} component={CreateAssessment} />
                <RoleRoute path={`${process.env.PUBLIC_URL}/admin/globals`} component={Globals} />
              </ComponentLayout>
            </App>
          </Switch>
        </AuthUserProvider>
      </Security>
    </div>
  );
};

const RootWithRouter = withRouter(Root);

export const ComponentToRender = <Provider store={store}>
  <Router basename={"/"}>
    <RootWithRouter />
  </Router>
</Provider>;

ReactDOM.render(
  ComponentToRender,
  document.getElementById("root"));

serviceWorker.unregister();