import React from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import Links from "../../components/common/Links";

const Kozuchi = () => {
  return (
    <div>
      <Breadcrumb title="Kozuchi" parent="Kozuchi" />
      <Links pathname={window.location.pathname} />
    </div>
  );
};
export default Kozuchi;
