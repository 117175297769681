import React from "react";
import Modal from "react-responsive-modal";
import { Media } from "reactstrap";
import { setToast } from "../../components/common/Toast";
import { toast } from "react-toastify";
import axios from "axios";

export const convertToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      /* istanbul ignore next */
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      /* istanbul ignore next */
      reject(error);
    };
  });
};

const LinkModal = (props) => {
  const handleSubmit = () => {
    if (props.existing) {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/links/${props.link._id}`, {
        link: props.link
      })
        .then(() => {
          setToast(toast.success, "Link updated");
          onCloseModalUpload();
        })
        .catch(() => {
          setToast(toast.error, "Unable to update link");
        });
    } else {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/links`, {
        link: props.link
      })
        .then(() => {
          setToast(toast.success, "Link added");
          onCloseModalUpload();
        })
        .catch(() => {
          setToast(toast.error, "Unable to add link");
        });
    }
  };

  const onCloseModalUpload = () => {
    props.setOpenUpload(false);
    props.setExisting(false);
    props.setLink({
      name: "",
      image: "",
      link: "",
      page: ""
    });
    props.getLinks();
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    props.setLink({ ...props.link, image: base64 });
  };

  return (
    <Modal open={props.openUpload} onClose={onCloseModalUpload}>
      <div className="container-fluid">
        <div className="row">
          <h6 className="f-w-600 text-align-centre">Link</h6>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form className="dropzone dropzone-primary" id="singleFileUpload">
                  <h6 className="f-w-600 text-align-centre">Create a Link</h6>

                  <p className="form-text">Name</p>
                  <input
                    id="name"
                    type="text"
                    className="form-control"
                    value={props.link.name}
                    onChange={(e) => props.setLink({ ...props.link, name: e.target.value })}
                  />

                  <p className="form-text">Page</p>
                  <select id="page" className="form-control btn-square" value={props.link.page} onChange={(e) => props.setLink({ ...props.link, page: e.target.value })} style={{ overflow: "hidden", textOverflow: "ellipsis", width: "20rem" }}>
                    <option value={undefined} disabled={props.link.page !== ""}>None</option>
                    <option value={"/discovery/tools"}>/discovery/tools</option>
                    <option value={"/enterprise-architecture"}>/enterprise-architecture</option>
                    <option value={"/database-factory/mongodb"}>/database-factory/mongodb</option>
                    <option value={"/database-factory/workflow-tool"}>/database-factory/workflow-tool</option>
                    <option value={"/service/service-now"}>/service/service-now</option>
                    <option value={"/apis/kong"}>/apis/kong</option>
                    <option value={"/repository"}>/repository</option>
                    <option value={"/workplace"}>/workplace</option>
                    <option value={"/kozuchi"}>/kozuchi</option>
                    <option value={"/secrets/vault"}>/secrets/vault</option>
                    <option value={"/sustainability/carbon-footprint"}>/sustainability/carbon-footprint</option>
                    <option value={"/data/data-virtualisation"}>/data/data-virtualisation</option>
                    <option value={"/data/data-bus"}>/data/data-bus</option>
                    <option value={"/support-ticket"}>/support-ticket</option>
                  </select>

                  <p className="form-text">Link</p>
                  <textarea
                    id="link"
                    type="text"
                    rows={2}
                    className="form-control"
                    value={props.link.link}
                    onChange={(e) => props.setLink({ ...props.link, link: e.target.value })}
                  />

                  <input
                    type="file"
                    name="file"
                    id="file"
                    className="inputfile"
                    accept=".jpeg, .png, .jpg"
                    onChange={(e) => handleFileUpload(e)}
                  />

                  {props.link.image &&
                    <div>
                      <p className="form-text">Image</p>
                      <Media
                        src={props.link.image}
                        alt="Gallery"
                        className="img-thumbnail"
                        style={{ marginBottom: "10px" }}
                      />
                    </div>
                  }

                  <label className="btn btn-primary" htmlFor="file" style={{ width: "100%" }}>{props.link.image ? "Change image" : "Choose an image"}</label>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button id="submit" className="btn btn-pill btn-primary btn-air-primary btn-modal" disabled={Object.keys(props.link).some((key) => props.link[key] === "")} onClick={() => handleSubmit()} type="button">Submit</button>
    </Modal>
  );
};

export default LinkModal;